<template>
  <div class="background" ref="card">
    <a-card class="card">
      <div>
        <div style="text-align: center; padding-top: 16px">
          <img
            :src="logo"
            width="170"
            style="margin-top: -6px; margin-left: 0px; cursor: pointer"
           
          />
        </div>
        <div class="title" style="margin: 0">
          <span style="cursor: pointer" >店管家</span>
        </div>
        <div
          style="
            text-align: center;
            font-size: 16px;
            color: #666;
            margin-bottom: 24px;
          "
        >
        最懂你的销售管理
        </div>
      </div>
      <div class="container">
        <div>
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 14 }"
          >
            <a-form-model-item prop="username" label="用户名">
              <a-input
                size="large"
                v-model="form.username"
                placeholder="用户名或手机号"
              />
            </a-form-model-item>
            <a-form-model-item prop="password" label="密码">
              <a-input-password size="large" v-model="form.password" />
            </a-form-model-item>
          </a-form-model>
        </div>

        <!-- <a-row>
      <a-col :span="7" offset="5">
        <a-button type="link" style="float: left; padding: 0;" @click="$router.push('/user/set_password')">忘记密码
        </a-button>
      </a-col>
      <a-col :span="7">
        <a-button type="link" style="float: right; padding: 0;" @click="$router.push('/user/register')">注册账号</a-button>
      </a-col>
    </a-row> -->

        <a-row>
          <a-col :span="14" offset="5">
            <a-button
              type="primary"
              size="large"
              :loading="isLoading"
              style="width: 100%"
              @click="login"
              >登录</a-button
            >
          </a-col>
        </a-row>

        <div style="text-align: center; width: 100%; margin-top: 24px;">
          <div>
            问题咨询请扫描下方客服二维码
          </div>
          <div>
            <img :src="wechatCustomerService" width="100" style="margin-top: 8px;" />
          </div>
        </div>
    
        <!-- <div style="text-align: center; font-size: 16px; color: #40a9ff; margin-bottom: 24px; margin-top: 28px;">此版本为试用版, 如需定制或购买请联系我们</div> -->
      </div>
    </a-card>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      logo: require("@/assets/logo.png"),
      wechatCustomerService: require('@/assets/wechat_customer_service.png'),
      isLoading: false,
      form: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "change" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    initialize() {
      document.onkeypress = (e) => {
        let code = document.all ? event.keyCode : e.which;
        if (code == 13) {
          this.login();
          return false;
        }
      };
    },
    login() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.isLoading = true;
          this.$axios
            .post("users/login/", this.form)
            .then((res) => {
              this.$store.dispatch("getUserInfo").then(() => {
                this.$router.push("/");
              });
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style lang="less" scoped>
.container {
  // width: 480px;
  // margin: auto;
  // text-align: left;
  // background: #fff;
}
.card {
  width: 480px;
  border-radius: 8px;
  padding-bottom: 64px;
  margin: auto;
}

.background {
  min-height: 100vh;
  /* padding: 0 0 10vh 0; */
  background: #f0f2f5 url("~@/assets/background.svg") no-repeat 50%;
}

.title {
  text-align: center;
  font-size: 32px;
  color: #1890ff;
  margin-top: 8px;
  font-weight: bold;
}
</style>